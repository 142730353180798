/*!
 * Start Bootstrap - One Page Wonder v5.0.8 (https://startbootstrap.com/theme/one-page-wonder)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-one-page-wonder/blob/master/LICENSE)
 */
body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  /* font-family: Inter, sans-serif; */
  font-family: Cormorant Garamond semi bold;
  font-style: normal;
  font-weight: bold;
}

/* body {
    font-family: 'Lato';
    background: #E5E5E5;
  } */

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: bold;
}

.fixed-top {
  /* font-family: Inter, sans-serif; */
  font-family: Cormorant Garamond semi bold;
  /* top: 10px;
    right: 10px;
    left: 10px; */
}

.btn-xl {
  text-transform: uppercase;
  padding: 1.5rem 3rem;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.bg-black {
  background-color: #000 !important;
}

.rounded-pill {
  border-radius: 5rem;
}

.navbar-custom {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #1C252D;
  /* background-color: rgba(0, 0, 0, 0.7); */
}

.navbar-custom .navbar-brand {
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
}

.navbar-custom .navbar-nav .nav-item .nav-link {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  color: #ffffffe3;
}

header.masthead {
  padding-top: 5rem;
  /* padding: 11.5rem; */
  /* padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem); */

  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

header.masthead .card {
  background: rgba(249, 249, 249, 0.8);
  padding: 0.25rem 2.25rem 0.25rem 2.25rem;
}

header.masthead .card p {
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #787878;
  mix-blend-mode: normal;
}

header.masthead .card h5 {

  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #1C252D;
}

header.masthead h1 {
  font-size: 2.25rem;
}

@media (min-width: 992px) {
  header.masthead {
    /* height: 70vh; */
    /* min-height: 20rem; */
    /* height: 100vh;
    min-height: 40rem;
    padding-top: 4.5rem; */
    padding-bottom: 0;
  }

  header.masthead p {
    font-size: 1.15rem;
  }

  header.masthead h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 3.5rem;
  }
}

.bg-primary {
  background-color: #ee0979 !important;
}

.btn-primary {
  background-color: #ee0979;
  border-color: #ee0979;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #bd0760 !important;
  border-color: #bd0760 !important;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 9, 121, 0.5);
}

.btn-secondary {
  background-color: #ff6a00;
  border-color: #ff6a00;
}

.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #cc5500 !important;
  border-color: #cc5500 !important;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 106, 0, 0.5);
}

.btn-outline {
  color: #4fbfa8;
  /* background-color: #ffffff; */
  border-color: #4fbfa8;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.btn-outline {
  color: #4fbfa8;
  /* background-color: #ffffff; */
  border-color: #4fbfa8;
  font-weight: bold;
  border-radius: 0;
}

.btn-outline:hover,
.btn-outline:active,
.btn-outline:focus,
.btn-outline.active {
  background: #4fbfa8;
  color: #ffffff;
  border-color: #4fbfa8;

}


.btn-orange {
  color: #fff !important;
  background-color: #EA5842;
  border-color: #EA5842;
  font-weight: bold;
  letter-spacing: 0.05em;
  border-radius: 0;
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-size: 14px;
  text-align: center;
}

.btn-orange:hover,
.btn-orange:active,
.btn-orange:focus,
.btn-orange.active {
  /* let's darken #EA5842 a bit for hover effect */
  background: #f73517;
  color: #ffffff;
  border-color: #f73517;
}

.btn-blue {
  color: #fff !important;
  background-color: #057bf6;
  border-color: #057bf6;
  font-weight: bold;
  letter-spacing: 0.05em;
  border-radius: 0;
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-size: 14px;
  text-align: center;
}

.btn-blue:hover,
.btn-blue:active,
.btn-blue:focus,
.btn-blue.active {
  /* let's darken #057bf6 a bit for hover effect */
  background: #0268d4;
  color: #ffffff;
  border-color: #0268d4;
}


.btn-outline-white {
  color: #ffffff;
  /* background-color: #ffffff; */
  border-color: #ffffff;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.btn-outline-white {
  color: #ffffff;
  /* background-color: #ffffff; */
  border-color: #ffffff;
  font-weight: bold;
  border-radius: 0;
}

.btn-outline-white:hover,
.btn-outline-white:active,
.btn-outline-white:focus,
.btn-outline-white.active {
  background: #ffffff;
  color: #000;
  border-color: #ffffff;

}

a {
  color: #1367ED;

}

.btn-outline-black {
  color: #787878;
  /* background-color: #ffffff; */
  border-color: #787878;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.btn-outline-black {
  color: #787878;
  /* background-color: #ffffff; */
  border: 1px solid #787878;
  font-weight: bold;
  border-radius: 0;
}

.btn-outline-black:hover,
.btn-outline-black:active,
.btn-outline-black:focus,
.btn-outline-black.active {
  background: #ffffffd0;
  color: rgba(20, 20, 20, 0.733);
  border-color: #787878e5;

}

.btn-header {
  color: #fff !important;
  background-color: #057BF6;
  border-color: #057BF6;
  font-weight: bold;
  letter-spacing: 0.05em;
  border-radius: 0;
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.btn-header:hover,
.btn-header:active,
.btn-header:focus,
.btn-header.active {
  /* let's darken #057BF6 a bit for hover effect */
  background: #013974;
  color: #ffffff;
  border-color: #013974;
}

.black-border {
  border: 1px solid #787878;
  box-sizing: border-box;
}

.footer {
  padding-top: 3rem;
  padding-bottom: 1rem;
  background-color: #1C252D;
  color: #fff
}

.footer span {
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  padding-right: 10px;
  color: #DFDFDF;
}

.footer small {
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */


  color: #DFDFDF;
}

.copyright {
  background-color: #1C252D
}

.btn-social {
  color: #DFDFDF;
  font-size: 24px;
  /* padding: 5px; */
}

.custom-textbox {
  background: #1C252D;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 0;
  height: 25px;
}

hr.divider {
  width: 95%;
  border: 1px solid #f1f1f166;
  position: absolute;
  left: 25px;
}

h2.display-4 {
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 48px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #000000;
}

.no-border {
  background: #e5e5e5 !important;
  border: none !important;
}

p.display-1 {
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #787878;
}

.btn-outline-orange {
  color: #ca5541;
  /* background-color: #ffffff; */
  border-color: #ca5541;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.btn-outline-orange {
  color: #ca5541;
  /* background-color: #ffffff; */
  border-color: #ca5541;
  font-weight: bold;
  border-radius: 0;
}

.btn-outline-orange:hover,
.btn-outline-orange:active,
.btn-outline-orange:focus,
.btn-outline-orange.active {
  background: #f33a1a;
  color: #ffffff;
  border-color: #f33a1a;

}

input[type=text],
input[type=email] {
  background: #FFFFFF;
  border: 1px solid #ACACAC;
  box-sizing: border-box;
  border-radius: 0;
}

textarea#message,
select#recurrence,
select#campaigns {
  background: #FFFFFF;
  border: 1px solid #ACACAC;
  box-sizing: border-box;
  border-radius: 0;
}


/* Choose all input elements that have the attribute: type="radio" and make them disappear.*/
input[type="radio"].tog {
  display: none;
  background: #FFFFFF;
}

/* The label is what's left to style. 
As long as its 'for' attribute matches the input's 'id', it will maintain the function of a radio button. */
label.tog {
  padding: 2px 25px;
  margin: 0 10px;
  /* padding: 2em; */
  display: inline-block;
  border: 1px solid grey;
  cursor: pointer;
  background: #FFFFFF;
}

.blank-label {
  display: none;
  padding: 2px 25px;
  margin: 0 10px;
}

/* The '+' is the adjacent sibling selector.
It selects what ever element comes right after it,
as long as it is a sibling. */
input[type="radio"].tog:checked+label {
  /* background: #EA5842; */
  border: 1px solid #EA5842;
  color: #EA5842;
  /* background: grey;
  color: #fff; */
  background: #FFFFFF;
}

address p {
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* text-align: center; */
  /* color: #787878;*/
}

.card-caption {
  color: #1367ED;
  font: Cormorant Garamond semi bold;
  /* color: #057BF6; */
  /* font-family: Inter, sans-serif; */
  /* color: #EA5842; */
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
}

.card-content {
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #787878;
}

.icon-layers {
  height: 100px;
  /* width: 100px; */
}

header.mhead {
  /* padding-top: 10rem; */
  /* padding-bottom: calc(10rem - 4.5rem); */
  /* background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url("../img/img1.png"); */

  /* background: url("../img/img1.png"); */
  /* background-position: center; */
  background-repeat: no-repeat;
  /* background-attachment: scroll; */
  /* background-size: cover; */
}

header.mhead .card {
  /* background: rgba(249, 249, 249, 0.8); */
  background: rgb(26 128 234);
  /* padding: 0.25rem 2.25rem 0.25rem 2.25rem; */
}

header.mhead .card p {
  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #FFF;
  /* color: #787878; */
  text-align: justify;
  line-height: 22px;
}

header.mhead .card h5 {

  /* font-family: Inter, sans-serif; */
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */

  display: flex;
  align-items: center;
  text-align: center;
  color: #FFF;
  /* color: #1C252D; */
}

header.mhead h1 {
  font-size: 2.25rem;
}

@media (min-width: 992px) {
  header.mhead {
    height: 50vh;
    /* min-height: 40rem; */
    padding-top: 4.5rem;
    padding-bottom: 0;
  }

  header.mhead p {
    font-size: 1.15rem;
  }

  header.mhead h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  header.mhead h1 {
    font-size: 3.5rem;
  }
}

p.page-section {
  font-size: 16px !important;
  line-height: 27px;
  color: #FFFFFF;
}

.card.no-border-card {
  border: none;
  background: none;
}

.card-body.caption-txt {
  background: #FFFFFF;
  width: 85%;
  margin: -2.5rem 0 0 2.5rem;
  padding: 2rem;
  text-align: left;
}

.card-title.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #EA5842;
}

.card-text.content {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #787878;
}

.card-text.header {
  font-size: 35px;
  color: #057bf6;
  justify-content: left;
  text-align: center;
}

label.newletter {
  color: #dfdfdf;
  font-size: 10px;
  /* margin-left: 25px; */
}

.alternate {
  background-color: #f9f9f9;
  padding-bottom: 30px;
  /* padding-top: 8.5rem; */
  padding-left: 20px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

/* .alternate a {
  color:#3358FF;

} */
.bottomMargin25 {
  margin-bottom: 25px;
}

.center {
  text-align: center;
}

.marginTop50 {
  margin-top: 50px;
}

.tagline {
  font-size: 1.9em;
  color: #000;
  margin-left: 25px;
  margin-right: 25px;
}

.spacedmedium {
  line-height: 1.75em;
}

.social a {
  color: #ef4a25;
  font-size: 3em;
}

.social.dark a {
  color: #000;
  font-size: 1.5em;
}

.social a:hover {
  color: #277CB4;
}

.social a:focus {
  color: #277CB4;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.right10 {
  margin-right: 10px;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.no-background {
  background-color: #f9f9f9 !important;
}

figure.work {
  border: thin #c0c0c0 solid;
  display: flex;
  flex-flow: column;
  padding: 5px;
  width: 60%;
  margin-top: 25px;
  /* max-width: 220px; */
  /* margin: auto; */
  border-radius: 4%;
  display: none;
}

.img-border {
  /* max-width: 220px;
  max-height: 150px; */
  border-radius: 4%;
  width: 100%;
}

figcaption {
  padding-left: 30%;
}

/* #gridCheckbox6:checked + #other-div {
  display: block !important;
} */
#gridCheckbox6:not(:checked)+#other-div {
  display: none;
}

figure.logo-img {
  position: relative;
  top: 88px;
}

.quote {
  margin: 0;
  /* background: #eee; */
  padding: 0.5em;
  border-radius: 1em;
}

.quote figcaption,
.quote blockquote {
  margin: 1em;
  padding-left: 0;
}

.right-caption {
  font-weight: 500;
  padding-top: 10px;
  text-align: justify;
  font-size: 18px;
}

#Layer_2,
#Layer_3,
#Layer_4 #Layer_5,
#Layer_6,
#Layer_7,
#Layer_8,
#Layer_9,
#Layer_10,
#Layer_11,
#Layer_13,
#Layer_12 {
  cursor: pointer;
}

.svggroup {
  pointer-events: all;
}



body {
  /* font-family: 'Lato', sans-serif */
}

h1 {
  margin-bottom: 40px
}

label {
  color: #333
}

.btn-send {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-left: 3px;
  outline: none;
  font-weight: bolder;
}

.help-block.with-errors {
  color: #ff5050;
  margin-top: 5px
}

.card {
  margin-left: 10px;
  margin-right: 10px
}

.error-messages {
  color: red;
  font-size: 16px;
}

.success-messages {
  height: 400px;
  color: green;
  font-size: x-large;
}

.sub-container {
  font-size: 14px;
  width: 52%;
}

.sub-text-p {
  position: absolute;
  z-index: 10;
  margin-top: -22px;
}

.sub-btn {
  margin-top: -5px;
}

.carousel-inner>.item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -moz-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.steps span {
  position: relative;
  display: inline-block;
  color: #002D55;
  background-color: #ecf6fc;
  height: 39px;
  padding: 6px 0 5px 40px;
  float: left;
  width: 32.3%;
  white-space: nowrap;
  cursor: pointer;
}

.steps .active {
  color: #fff;
  background-color: #002D55;
}

.steps span.no-active:after,
.steps span.no-active:before,
.steps span.active:after,
.steps span.active:before {
  position: absolute;
  top: 0;
  right: -22px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 19.5px 0 19.5px 20px;
  border-color: transparent transparent transparent #fff;
  display: inline-block;
  z-index: 9;
}

.steps span.active:after {
  border-color: transparent transparent transparent #002D55;
}

.steps span.no-active:after,
.steps span.active:after {
  border-style: solid;
  border-width: 19.5px 0 19.5px 20px;
  border-color: transparent transparent transparent #ecf6fc;
  z-index: 10;
  right: -20px;
}

.steps span.no-active:after,
.steps span.no-active:before,
.steps span.active:after,
.steps span.active:before {
  position: absolute;
  top: 0;
  right: -22px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 19.5px 0 19.5px 20px;
  border-color: transparent transparent transparent #fff;
  display: inline-block;
  z-index: 9;
}

.steps span.no-active:after,
.steps span.active:after {
  border-style: solid;
  border-width: 19.5px 0 19.5px 20px;
  border-color: transparent transparent transparent #ecf6fc;
  z-index: 10;
  right: -20px;
}

.steps span.no-active:after,
.steps span.no-active:before,
.steps span.active:after,
.steps span.active:before {
  position: absolute;
  top: 0;
  right: -22px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 19.5px 0 19.5px 20px;
  border-color: transparent transparent transparent #fff;
  display: inline-block;
  z-index: 9;
}

.steps span.active:after {
  border-color: transparent transparent transparent #002d55;
  right: -20px;
}

.steps span.no-active:after {
  border-color: transparent transparent transparent #ecf6fc;
  right: -20px;
}

.section-headline {
  border-bottom: 1px solid rgba(224, 221, 214, 0.5);
  line-height: 1.2rem;
  font-size: 2rem;
  margin-top: 4.5rem !important;
  margin-bottom: 1.0rem;
}

.section-headline span {
  display: inline-block;
  background-color: #f7f9fa;
  padding-bottom: 5px;
  margin-bottom: -16px;
  padding-right: 10px;
  color: #E60005;
  font-weight: normal;
  word-break: normal;
  word-wrap: inherit;
}

#amount,
#purpose {
  width: 30%;
}

.donate-page {
  margin-bottom: 1rem;
}

.donate-page .card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125) !important;
  border-radius: 0.0rem;
}

.donate-page label {
  margin-bottom: 1.0rem;
  margin-top: 1.5rem;
  font-family: HelveticaNeueLT, "Open Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  color: #554f4a;

}

input[type=radio] {
  padding: 0.3em;
  -webkit-appearance: none;
  outline: 0.1em solid #002d55c5;
  outline-offset: 0.1em;
  cursor: pointer;
}

input[type=radio]:checked {
  display: inline-block;
  background-color: #002d55c5;
}

label>span {
  padding-left: 10px;
}

.f-14 {
  font-size: 14px;
}

img.selected {
  align-self: center;
}

.responsibility {
  font-size: 12px;
  line-height: 16px;
  padding-top: 40px;
  color: #554f4a;
  font-family: HelveticaNeueLT, "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: normal;
}

a.pre {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
}

a.pre:hover {
  background-color: #ddd;
  color: black;
}

.previous {
  background-color: #f1f1f1;
  color: black;
}

.next {
  background-color: #04AA6D;
  color: white;
}

.inline-errormsg {
  color: red;
  font-size: 12px;
}

.hidden {
  display: none;
}

/* 
.payment {
  border: 1px solid #f2f2f2;
  height: 280px;
  border-radius: 20px;
  background: #fff;
}

.payment_header {
  background: rgb(61, 222, 83);
  padding: 20px;
  border-radius: 20px 20px 0px 0px;

}

.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}

.content a {
  width: 200px;
  height: 35px;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  background: rgb(68, 247, 101);
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
} */
.success-card {
  background: white;
  padding: 50px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 5% 20%;
  font-size: 16px;
  line-height: 1.6;
  color: #554f4a;
  font-family: HelveticaNeueLT, "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.success-msg {
  color: #88B04B;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
  margin-left: 30%
}

.error-msg {
  color: hsl(0, 93%, 51%);
  font-weight: 500;
  font-size: 35px;
}

i.checkmark {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left: 50px;
}

i.checkmark-error {
  color: hsl(0, 93%, 51%);
  font-size: 100px;
  line-height: 185px;
  margin-left: 45px;
}

.status-card-wrap {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background-color: #F8FAF5;
  margin-left: 20%;
}

#currency {
  position: absolute;
  width: 10%;
  float: right;
  left: 282px;
}

.carousel-txt {
  text-align: center;
  font-size: 20px;
  color: #057bf6;
  font-weight: bolder;
}

.carousel-src {
  text-align: center;
  font-size: 18px;
  color: #2196f3;
  font-weight: bolder;
  padding-bottom: 18px;
  font-style: italic;
}

#carouselMediaTxt li {
  background-color: #057bf636;
}

#carouselMediaTxt li.active {
  background-color: #057bf6;
}

.thumbnail {
  margin-top: 15px;
  margin-bottom: 15px;
}

.center-item {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.bb3 {
  width: 80%;
  margin: 0 auto;
}

.bandedBlock {
  padding: 3em 0;
  overflow: hidden;
}

.steps-container {
  display: flex;
  list-style: none;
}

.steps-container li {
  width: 50%;
}

.bb3 .steps-container li .step {
  color: #057bf6;
  font-weight: bold;
  font-size: 2em;
  background: #d7d7d7;
  border-radius: 50%;
  display: inline-block;
  padding: 0.5em;
  float: left;
  margin-right: 0.25em;
  width: auto;
  line-height: .5em;
}

.bb3 .steps-container li .step-details {
  float: left;
  width: 70%;
}

.bb3 .steps-container li .action {
  color: #057bf6;
  text-transform: uppercase;
  font-weight: bold;
  /* font-size: 2em; */
  line-height: 1em;
}

.bb3 .steps-container li .action-details {
  display: block;
  color: #47606c;
}

.display-p {
  font-weight: 500;
  font-size: 1.3rem;
  text-align: justify;
}

.social-media-link {
  position: absolute;
  background: #628490;
  padding: 1px 10px;
  box-shadow: 4px 5px 3px #628490;
}