body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
    /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
    /* sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Cormorant Garamond semi bold;
}

#root{
  background: #f0f3f587 !important;
}
.card-text-a{
  line-height: 1.6;
  overflow-wrap: break-word !important;
  word-break: break-word;
  max-width: 59.5rem;
  margin: 0 auto;
  /* padding-top: 1.5rem; */
  font-size: 1.1rem;
  font-weight: 500;
  text-align: justify;
}

.language {

}
@media (max-width: 991px) {
  .language {
    bottom: 10px;
  }
}
.card{
  border: none !important;
  background-color: #f7f9fa;
}
h3.card-title {
  color: #057bf6;
  justify-content: left;
}

ul.question >li {
  list-style:none;
}   
ul.question >li:nth-child(odd):before {
  content:"Q. - ";
  font-weight: bolder;  
}
ul.question >li:nth-child(even):before {
  content:"A. - "; 
  font-weight: bolder;
}